@use '@/styles/utils/mixins.scss' as *;

.imageMarqueeWrapper {
  display: flex;
  .imageMarqueeLink {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 30rem;
    display: flex;

    .imageMarquee {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      .imageMarquee {
        transform: scale(1.1);
      }
    }
  }
  .imageMarqueeContaier {
    width: 25.875rem;
    margin-left: 2.5rem;
    @include mob() {
      margin-left: 0;
      width: 100%;
    }
  }
}
